﻿body {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 11px;
}

::-webkit-scrollbar-track {
    background: $lightergray;
}

::-webkit-scrollbar-thumb {
    background-color: $lightgray;
    border-radius: 0;
    border: 0;
    border-left: 3px solid $lightergray;
    border-right: 3px solid $lightergray;
}

.wrapper {
    &-page {
        background-color: $lightergray;
        position: relative;
        // padding-top: 0;
        @include media-breakpoint-up(md) {
            padding-bottom: 15px;
            padding-top: 15px;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 100px;
            padding-top: 100px;
        }

        &>.container{
            padding-left: 0;
            padding-right: 0;
            @include media-breakpoint-up(lg) {
                padding-left: $container-padding-x;
                padding-right: $container-padding-x;
            }
        }

        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 50%;
        //     margin-left: -50vw;
        //     z-index: -1;
        //     height: 100%;
        //     width: 100vw;
        //     background-color: $lightergray;
        // }
    }
}

.svg-border {
    display: none;
    position: absolute;
    left: -5px;
    right: -5px;
    @include media-breakpoint-up(lg){
        display: block;
    }
    &--top{
        top: 0;
    }
    &--bottom{
        bottom: 0;
    }

}

.col-padding{
    &__top{
        padding-top: 50px;
    }
}
.col-margin{
    &__bottom{
        margin-bottom: 40px;
    }
}

.no-gutters {

    @include media-breakpoint-up(md){
        margin-right: -$container-padding-x;
        margin-left: -$container-padding-x;
      
        > .col,
        > [class*="col-"] {
          padding-right: 20px;
          padding-left: 20px;
        }
    }

    > .bunner{
        padding-right:20px;
        padding-left: 20px;
    }
}

.dinamic{
    &__wrapper{
        padding: 35px 45px;

        .form-control{
            border-radius: 8px;
            padding: 0 1em;
        }
    }
    &__title{
        font-size: 44px;
        line-height: 1;
        font-family: 'Bebas Neue';
    }
    &__desc{
        font-size: 12px;
        line-height: 1.5;
        font-weight: 400;
    }
    &__img{
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 300px;
        
        @include media-breakpoint-up(lg){
            background-position: center bottom;
            min-height: auto;
        }
    }
}

.dona-mobile__wrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 99;
    background-color: $white;
}

#collapsepTxt{
    &.collapse:not(.show) {
        display: block;
        height: 3rem;
        overflow: hidden;
    }
    &.collapsing {
        height: 3rem;
    }
}
