﻿a,button{
    &:focus{
        outline: none;
    }
}

a{ @extend .a;}
.a {
    font-weight: 900;
    transition: $transition-base;
    &:hover {
        color: $black;
        text-decoration: none;
    }

    &--gray {
        color: $gray;
    }

    &--black {
        color: $black;

        &:hover{
            color: $primary;
        }
    }

    &--white {
        color: $white;

        &:hover{
            color: $primary;
        }
    }
}

h4{ @extend .h4;}
.h4{
    line-height: 29px;
    font-weight: 400;
}
small{
    font-size: 14px;
}