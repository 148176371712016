﻿.card-aism {
    @extend .card;
    border-radius: 0;
    padding: 0;
    box-shadow: 0 4px 16px $lightgray;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
        border-radius: 15px;
    }
    &>.card-header {
        background-color: $secondary;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 19px 0 19px 20px;

        @include media-breakpoint-up(md) {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        @include media-breakpoint-up(lg){
            padding: 19px 0 19px 40px;
        }

        & h3 {
            color: white;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 0;
        }
    }
    &>.card-body {
        padding: 20px 15px;
        @include media-breakpoint-up(lg){
            padding: 20px 40px;
        }
    }
    .text-line {
        @extend .h4;

        // &::before {
        //     content: "Anno di riferimento";
        //     position: absolute;
        //     margin-top: -18px;
        //     color: $gray;
        //     font-size: 14px;
        // }
    }
    .text-no-file {
        color: $gray;
        font-size: 14px;
    }
    // .btn {
    //     @include media-breakpoint-down(sm) {
    //         font-size: 10px;
    //     }
    // }
    .col-6 {
        @include media-breakpoint-down(sm) {
            padding: 0 5px;
        }
    }
    &--bunner{
        border-radius: 15px;

        :hover{
            cursor: pointer;
        }
    }

}
.card-dati {
    @extend .card-aism;
    &>.card-body {

        @include media-breakpoint-up(lg) {
            padding: 65px 90px;
        }
    }
    
}
.card-dona {
    @extend .card-aism;

    &>.card-body {

        @include media-breakpoint-up(lg) {
            padding: 49px 90px;
        }
    }
}
.card-num{
    @extend .col;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    &>.card{
        // width: 195px;
        // max-width: 100%;
        // height: 128px;
        // max-height: 100%;
        background-color: $lightgray;
        border: 1px solid $border-color;
        color: $secondary;
        transition: all .25s ease;
        height: 100%;
        @include media-breakpoint-up(xl){
            width: 195px;
            max-width: 100%;
            height: 128px;
            max-height: 100%;
        }
        
        &.selected{
            color: $white;
            background-color: $primary;
            border-color: $primary;
            transition: all .25s ease;
        }
    }
    & .card-body{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;

        &--large{
            @include media-breakpoint-down(xs){
                padding: 8px;
            }
        }

    }
    &__wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    &__price{
        font-weight: 900;
        font-size: 40px;
        line-height: 30px;
        @include media-breakpoint-up(md){
            font-size: 60px;
            line-height: 60px;
        }
    }
    &__currency{
        font-size: 20px;
        margin-left: 5px;
        font-weight: 400;
        @include media-breakpoint-up(lg){
            font-size: 30px;
        }
    }
    & label{
        font-size: 12px;
        color: inherit;
        text-transform: uppercase;
        font-weight: 900;
        @include media-breakpoint-up(lg){
            font-size: 14px;
        }
    }

    & .form-control{
        padding: 0 1em;
    }

    & .form-group{
        margin-bottom: 8px;
    }
}