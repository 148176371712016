﻿.btn {
    border-radius: 0.67em;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    @include media-breakpoint-up(lg){
        font-size: 12px;
    }

    &--header {
        max-width: 250px;
        @include media-breakpoint-up(lg){
            font-size: 14px;
        }

        &:hover{
            background-color: $secondary;
            border-color: $secondary;
        }
    }

    &-primary{
        &:hover{
            background-color: $secondary;
            border-color: $secondary;
        }
    }

    &--gray {
        max-width: 200px;
        min-width: 150px;
        background-color: $lightgray;
        color: $secondary;

        @include media-breakpoint-up(lg){
            min-width: 128px;
        } 

        &:hover{
            background-color: $primary;
            color: $white;
        }
    }

    &--scarica {
        @extend .btn-primary;
        max-width: 200px;
        min-width: 150px;
        @include media-breakpoint-up(lg){
            min-width: 128px;
        } 

        &::before {
            content: "";
            margin-right: 10px;
            background-image: url('../images/prlb/adobeacrobatreader.svg');
            height: 24px;
            width: 24px;
        }
    }

    &--submit, &--contact{
        @extend .btn--gray;
        max-width: 400px;
        // height: 64px;
    }

    // &--contact{
    //     @extend .btn--gray;
    //     max-width: 250px;
    // }
}

.g-signin2 {
    .abcRioButton {
        @extend .btn;
        @extend .btn-outline-go;
        border-radius: 0.67em;
        height: 50px !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        box-shadow: none !important;

        .abcRioButtonContents span {
            font-family: $font-family-base!important;
            font-weight: 900 !important;
            font-size: 14px !important;

            @include media-breakpoint-up(lg) {
                font-size: 12px !important;
            }
        }

        .abcRioButtonIcon {
            display: none;
        }
    }
}
