﻿.accordion {

    .card {
        border: 0px;
        border-bottom: 1px solid $border-color  !important;
        background: transparent;
        border-radius: 0px;
        padding: 1rem 0;
    }

    .card-header {
        padding: 0px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 0px;

        & a {
            padding: 0.667em 0px;
            display: block;
            width: 100%;
            color: $black;
            font-weight: 400;
        }
    }

    .card-body {
        padding: 9px 0;
        @include media-breakpoint-up(md){
            padding: 9px 32px;
        }

        & p {
            color: $gray;
            line-height: 20px;
        }
    }

    .card-header .card-body {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .card-body .card-header h5 {
        font-size: 18px;
    }

    .card-header a::before {
        content: "";
        margin-right: 20px;
        display: inline-block;
        width: 12px;
        height: 6px;
        background-image: url('../images/prlb/chevron-down-gray.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        vertical-align: middle;
        transition: transform 0.30s ease;
        transform: rotate(180deg);
    }

    .card-header a.collapsed::before {
        transform: rotate(0);
    }
}
