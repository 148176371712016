$margin-element: 5%;
.modal{

    &::-webkit-scrollbar {
        display: none;
    }
    
    &-open{
        & nav.navbar{
            padding-right: $navbar-padding-x !important;
        }
    }
    &-full {
        margin: 0;
        background: rgba($gray, .8);
        width: 100%;
        min-width: 100%;
        overflow: hidden;

        &__content {
            border: 0;
            height: 100vh;
            border-radius: 0;
            align-items: center;
            justify-content: center;
            background: transparent;
        }
    }
    &-close{
        @extend .btn;
        position: absolute;
        width: auto;
        right: $margin-element;
        top: $margin-element;
        font-weight: normal;
        text-shadow: none;
        z-index: 2000;
        padding: 0;
    }
}
.carousel-control-prev, .carousel-control-next{
    width: auto;
    opacity: 1;
}
.carousel-control-prev{
    left: $margin-element;
}
.carousel-control-next{
    right: $margin-element;
}
.carousel-indicators {
    &--popup{
        bottom: -5%;
        margin-top: auto;
        margin-bottom: auto;
        & li {
            width: 16px;
            height: 16px;
            margin-right: 9px;
            margin-left: 9px;
            background-color: #fff;
            opacity: 1;
            border-radius: 50%;
            &.active{
                background-color: $secondary;
            }
        }
    }

    &__donazioni{
        // @extend .carousel-indicators;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        flex-grow: 1;
        & .carousel-inner{
            width: 162px;
        }
    }
    &__elm{
        width: 30px;
        height: 30px;
        border-radius: 8px;
        border: 1px solid $lightgray;
        color: $lightgray;
        text-indent: 0;
        text-align: center;
        line-height: 30px;
        margin-right: 4px;
        margin-left: 4px;
        display: inline-block;
        &:hover{
            cursor: pointer;
        }
        &.active{
            background-color: #fff !important;
            border: 1px solid $gray;
            color: $gray;
        }

        &--next, &--prev{
            background-image: url('../images/prlb/chevron-down-gray.svg');
            background-position: center;
            background-size: 10px 10px, 10px 10px;
            background-repeat: no-repeat;
            transform: rotate(-90deg);
        }
        &--prev{
            transform: rotate(90deg);
        }
    }
}
.carousel{
    &__wrapper{
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        text-align: center;
        height: 70vh;
        display: flex;
        flex-flow: column wrap; 
        justify-content: center;
        align-items: center;
    }
    &__img{
        margin-bottom: 25px;
        width: 100%;
        max-width: 115px;
        height: 150px;
    }
    &__text{
        color: $white;
        font-weight: 900;
        font-size: 18px;
        line-height: 1.3;
        @include media-breakpoint-up(md){
            font-size: 28px;
        }

        & p{
            margin-bottom: 0;
        }
    }
    &__btn{
        @extend .btn, .btn-primary, .btn--header;
        margin-top: 25px;
        @include media-breakpoint-up(md){
            margin-top: 50px;
        } 
    }
}



#birthDateModal{
    .modal-content{
        border: 0;
        border-radius: 0;
        @include media-breakpoint-up(md) {
            border-radius: 15px;
        }
    }

    .card-aism{
        border: 0;
        box-shadow: 0 4px 16px $dark;
        .card-header{
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;

            @include media-breakpoint-up(lg){
                padding: 19px 40px;
            }
        }

        .close{
            color: $white;
            text-shadow: none;
            font-size: 1.9rem;
            opacity: 1;
        }
    }
}