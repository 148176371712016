﻿footer{
    padding-top: 60px;
    padding-bottom: 60px;

    // @include media-breakpoint-up(md){
    //     padding-bottom: 60px;
    // }
}
.footer {
    &__wrapper {
        text-align: center;
    }

    &__logo {
        margin-bottom: 20px;
        width: 50px;
        height: auto;

        @include media-breakpoint-up(lg) {
            width: 83px;
        }
    }

    &__p {
        font-size: 14px;
        line-height: 1;
        color: $gray;
        font-weight: 900;
        margin-bottom: 0;

        &--small {
            font-weight: 400;
            margin-top: 10px;
        }
    }

    &__menu {
        margin-top: 20px;

        & .nav-link {
            font-size: 12px;
            padding: .5em;
            border-left: 1px solid $border-color;
            font-weight: 400;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                padding: .5em 1.25em;
            }
        }
    }
}

.torna-su {
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    color: $gray;
    display: inline-block;
    position: absolute;
    top: 0;
    right: $grid-gutter-width / 2;

    @include media-breakpoint-up(lg){
        font-size: 10px;
    }

    &__icon {
        width: 35px;
        height: 35px;
        background-color: $primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        @include media-breakpoint-up(lg){
            width: 40px;
            height: 40px;
        }

        & img {
            width: 12px;
            height: auto;
        }
    }
}
