.navbar {
    &-toggler {
        padding: 0;
        border: none;
        width: 30px;
        height: 22px;
        z-index: 2000;
        background: transparent !important;

        & .navbar-icon-bar {
            transition: all 0.3s ease;

            &--top {
                transform: rotate(45deg);
                transform-origin: 10% 10%;
            }

            &--middle {
                opacity: 0;
                width: 0;
                margin: 4px 0;
            }

            &--bottom {
                transform: rotate(-45deg);
                transform-origin: 10% 90%;
            }
        }

        &.collapsed {
            & .navbar-icon-bar {
                // transition: all 0.35s ease;
                &--top {
                    transform: rotate(0);
                }

                &--middle {
                    width: 30px;
                    opacity: 1;
                }

                &--bottom {
                    transform: rotate(0);
                }
            }
        }
    }

    &-icon-bar {
        width: 30px;
        height: 4px;
        background-color: $black;
        display: block;

        &--middle {
            margin: 5px 0;
        }
    }
}
