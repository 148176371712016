﻿.faq{
    &__panel{
        margin-bottom: 40px;
        width: 100%;
        max-width: 330px;
    }
}

.contact-profile{
    @extend .row;

    &__left{
        @extend .col-auto;
        padding-bottom: $grid-gutter-width/2;
    }
    &__right{
        @extend .col-auto;
        padding-bottom: $grid-gutter-width;
    }

    &__img{
        @extend .embed-responsive;
        @extend .embed-responsive-1by1;
        @extend .rounded-circle;
        max-width: 100%;
        width: 116px;

        & img{
            @extend .embed-responsive-item;
        }

        &--small{
            width: 70px;
        }
    }

    &__nome{
        font-weight: 900;
        font-size: 24px;
        color: $gray;
        margin-bottom: 0;
    }

    &__descrizione{
        border-bottom: 1px solid $border-color;
        padding-bottom: 1em;
        margin-bottom: 1em;
    }
    &__mail{
        margin-bottom: .4em;
        
        & img{
            width: 20px;
            height: auto;
        }
    }
    &__tel{
        margin-bottom: 0;
        
        & img{
            width: 20px;
            height: auto;
        }
    }

}
