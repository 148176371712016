﻿.container {
    &--top {
        @include media-breakpoint-up(lg) {
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                height: 1px;
                width: auto;
                right: 15px;
                left: 15px;
                bottom: 1px;
                z-index: 0;
                background-color: $lightgray;
            }
        }
    }
}
.navbar {

    @include media-breakpoint-up(lg) {
        position: static;
        box-shadow: none !important;
    }

    &-brand {
        margin-right: 0;
        padding: 0 12px 0 0;
        background-color: $white;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            padding-right: 24px;
        }

        &__logo {
            width: 50px;
            height: auto;

            @include media-breakpoint-up(lg) {
                width: 83px;
            }
        }
    }

    & .nav-link {
        text-transform: uppercase;
        color: $black;
        font-weight: 900;
        margin-left: 0;
        padding: 1em 0;
        font-size: 14px;
        line-height: 29px;
        border-top: 1px solid $border-color;

        &:hover {
            color: $secondary;
        }

        &:focus {
            outline: none;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 2em;
            border-top: 0;
            padding: 0 !important;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 3em;
        }
    }

    &-collapse {
        padding-top: $navbar-padding-y;

        @include media-breakpoint-up(lg){
            padding-top: 0;
        }

        &.collapsing {
            height: 100vh !important;
        }

        &.show {
            height: 100vh;
        }
    }

    &-spacer{
        margin-top: $navbar-padding-y*2+83.63px;
    }
}

.user-badge {
    font-size: 20px;
    line-height: 1em;
    font-weight: 400;
    border-radius: 15px;
    @include media-breakpoint-up(lg) {
        font-size: 28px;
    }

    &--insieme {
        font-size: 20px;
        color: $secondary;
        margin-bottom: 0;
        width: 200px;
        padding-right: 1em;

        @include media-breakpoint-up(lg) {
            font-size: 28px;
            width: auto;
        }
    }

    &--small {
        font-size: 0.57em;
        margin-top: 0.3em;
        color: $gray;
        margin-bottom:0;
    }

    &--mobile {
        padding: $navbar-padding-y 0;
        border-top: 1px solid $border-color;
        // background-color: $lightergray;
    }

    &--image {
        margin: 0 12px 0 0;
        width:30px;
        height:auto;
        @include media-breakpoint-up(lg){
            width: 60px;
        }
    }
}