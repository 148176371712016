.full-bg{
    position: relative;
    &::before{
        background-color: inherit;
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: -40px;
        right: -40px;
    }

    small{
        position: relative;
        z-index: 2;
    }
}

.row-card{
    padding: 20px 0;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        // left: -40px;
        // right: -40px;
        left: 0px;
        right: 0px;
        height: 1px;
        top: 0;
        background-color: $border-color;
    }

    &.first{
        &:before{
            display: none;
        }
    }

    & p{
        font-size: 18px;
        line-height: 1;
        margin-bottom: 0;
    }

    & .badge{
        color: $primary;
        text-transform: uppercase;
        width: 80px;
        white-space: inherit;

        & img{
            width: 80px;
        }
        
    }
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    margin-right: 1em;

    &--gold{
        background-color: gold;
    }

    &--green{
        background-color: forestgreen;
    }
}

.badge-circle{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 900;
    line-height: 18px;
    color: $secondary;
    background-color: $white;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-left: 10px;
}