﻿.form {

    &-group {

        & label {
            color: $gray;
        }
    }
    &-check {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 18px 0;

        & label {
            color: $gray;
            margin-bottom: 0;
            padding: 10px 0;
            min-width: 192px;
            display: flex;
            align-items: center;

            &:hover{
                cursor: pointer;
            }
        }

        & input[type="radio"]{
            display: none;

            &+label{
                & .check-rounded{
                    width: 30px;
                    height: 30px;
                    margin-right: 24px;
                    position: relative;
                    border-radius: 50%;
                    border: 1px solid $border-color;
                    display: block;
                    
                    &:after{
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        right: 5px;
                        bottom: 5px;
                        background-color: $secondary;
                        border-radius: 50%;
                        opacity: 0;
                        transition: $transition-base;
                    }
                }

                &:hover .check-rounded:after{
                    opacity: .3;
                }
            }

            &:checked{
                &+label{
                    font-weight: 900;
                    & .check-square:after{
                        opacity: 1;
                    }
                    & .check-rounded{
                        &:after{
                            background-color: $secondary;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        & .aspNetDisabled{
            &+label{
                cursor: disabled;
                
                & .check-square{
                    width: 30px;
                    height: 30px;
                    margin-right: 24px;
                    position: relative;
                    border-radius: 8px;
                    border: 1px solid $border-color;
                    display: block;
                    flex-shrink: 0;
                    opacity: .5;
                    
                    &:after{
                        content: "";
                        position: absolute;
                        left: 9px;
                        top: 2px;
                        width: 10px;
                        height: 20px;
                        border: solid $primary;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                        opacity: 0;
                        transition: $transition-base;
                    }
                }
            }

            &.checked{
                &+label{
                    & .check-square:after{
                        opacity: 1;
                    }
                }
            }
    
        }

        &--square{
            flex-wrap: nowrap;
            padding: 0;

            & label{
                font-size: 12px;
                line-height: 16px;
                color: $gray;
                padding: 0;
                display: flex;
                align-items: center;
            }

            & input[type="checkbox"], input[type="radio"]{
                display: none;
    
                &+label {
                    & .check-square{
                        width: 30px;
                        height: 30px;
                        margin-right: 24px;
                        position: relative;
                        border-radius: 8px;
                        border: 1px solid $border-color;
                        display: block;
                        flex-shrink: 0;
                        
                        &:after{
                            content: "";
                            position: absolute;
                            left: 9px;
                            top: 2px;
                            width: 10px;
                            height: 20px;
                            border: solid $primary;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                            opacity: 0;
                            transition: $transition-base;
                        }
                    }

                    &:hover .check-square:after{
                        opacity: .3;
                    }

                }
    
                &:checked{

                    &+label .check-square:after{
                        opacity: 1;
                    }
                } 
            }
        }


    }

    &-control {
        padding: 0 1.56em;
        // border: 1px solid $gray;
        border-radius: 0.75em;
        height: 46px;
        line-height: 46px;

        &:disabled{
            background-color: $white;
            opacity: .5;
        }

    }

}

select.form-control {
    background-image: url('../images/prlb/chevron-down-gray.svg');
    background-position: calc(100% - 30px) 50%, calc(100% - 20px) 50%, 100% 0;
    background-size: 10px 10px, 10px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}

label.label{
    &--big{
        font-size: 16px;
    }
}